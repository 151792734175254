<template>
  <u-input v-mask="'#######-##.####.#.##.####'" :required="required" :autofocus="autofocus" ref="definput" class="erp-input erp-autocomplete float-icon input-hide-loading" color="amber" v-model="terms"
           placeholder="" hide-underline
           :class="{hasIcon: icon}">
    <template v-if="icon" v-slot:before>
      <u-icon :color="iconColor" :name="icon" :type="iconType" class="m-l-sm"/>
    </template>
    <u-popover anchor="center right" :offset="[5, 5]" ref="popover" class="autocomplete-popover" :anchor-click="false" :no-focus="true" :no-refocus="true"
               @show="() => {
          this.$refs.definput.selectionOpen = true
          this.$emit('show')
        }"
               @hide="() => {
          this.$refs.definput.selectionOpen = false
          /this.$emit('hide')
        }"
    >
      <u-list separator>
        <u-item class="font-bold">
          <e-row class="full-width">
            <e-col>Possível duplicata</e-col>
            <e-col style="max-width: 30px; margin-left: 50px" class="flex items-center content-end text-right">
              <u-btn @click="$refs.popover.hide()" icon-type="fa" icon="times" flat rounded size="xs" color="grey-7" />
            </e-col>
          </e-row>
        </u-item>
        <u-item v-for="p in duplicates" :key="p.id">
          <e-col><div class="font-bold1" v-html="extractMatch(p.numero)"></div></e-col>
          <e-col style="max-width: 30px; margin-left: 50px" class="flex items-center content-end text-right">
            <u-btn class="rotate-45" @click="gotoProcesso(p)" icon-type="fa" icon="long-arrow-up" flat round size="sm" color="grey-7" />
          </e-col>
        </u-item>
      </u-list>
    </u-popover>
    <sl-loading v-if="loading" content="" class="size-18" />
    <u-icon class="sl-loading" v-if="!loading && duplicates && duplicates.length" name="exclamation-triangle" type="fa" />
    <div v-if="isNew" class="new"><span class="lbl">Novo</span></div>
  </u-input>
</template>

<script>
import {UInput, UPopover, UList, UItem} from "uloc-vue"
import SlLoading from "@/components/layout/components/Loading"
import {list} from "@/domain/processos/services"
import ERow from "@/components/layout/components/Row"
import ECol from "@/components/layout/components/Col"

export default {
  name: "NumeroProcessoInput",
  components: {ECol, ERow, SlLoading, UInput, UPopover, UList, UItem},
  props: {
    value: {
      required: true
    },
    icon: {
      type: String,
      default: null
    },
    iconType: {
      type: String,
      default: 'fa'
    },
    iconColor: {
      type: String,
      default: 'grey-7'
    },
    debounce: {
      type: Number,
      default: 500
    },
    tipo: {
      type: Number,
      default: 1
    },
    autofocus: {
      type: Boolean,
      default: true
    },
    required: {
      type: Boolean,
      default: true
    },
    router: {
      default: null
    }
  },
  data() {
    return {
      loading: false,
      terms: this.value && this.value.value ? this.value.value : this.value,
      selectedValue: null,
      duplicates: null
    }
  },
  watch: {
    terms(v) {
      this.$emit('input', this.terms)
      this.__delayTrigger(v)
    }
  },
  computed: {
    isNew() {
      return this.selectedValue && this.selectedValue.isNew && this.terms === this.selectedValue.value
    }
  },
  methods: {
    focus () {
      this.$refs.definput.focus()
    },
    __delayTrigger(v) {
      this.$refs.popover.hide()
      this.duplicates = null
      clearTimeout(this.timer)
      if (!v || !v.length) return
      if (!this.$refs.definput.shadow.hasFocus()) {
        return
      }
      this.timer = setTimeout(this.trigger, this.debounce)
    },
    trigger() {
      this.loading = true
      const popover = this.$refs.popover
      const terms = this.terms?.replace(/\D/g, '')
      let filtros = encodeURI(`&sortBy=name&descending=false&search=${terms}&forceSearchNumber=1`)
      list(5, 1, filtros)
          .then(response => {
            this.loading = false
            let result = response.data.result
            if (result.length) {
              this.duplicates = result
              popover.show()
            }
          }).catch(error => {
        this.loading = false
        this.alertApiError(error)
      })
    },
    extractMatch (name) {
      // return String(name).substr(0, this.terms.length)
      name = name.replace(/&/g, "&").replace(/</g, "<").replace(/>/g, ">").replace(/"/g, '"').replace(/'/g, "'")
      let terms = String(this.terms).replace(/&/g, "&").replace(/</g, "<").replace(/>/g, ">").replace(/"/g, '"').replace(/'/g, "'")
      return String(name).replace(terms, `<span class="marq">${this.terms}</span>`)
    },
    selected(item) {
      this.selectedValue = item
      console.log(item)
      this.$emit('input', item)
    },
    gotoProcesso (p) {
      let router = this.$router || this.router
      let routeData = router.resolve({name: 'processo', params: {id: p.id}})
      window.open(routeData.href, '_blank')
    }
  }
}
</script>
