var render = function render(){var _vm=this,_c=_vm._self._c;return _c('u-input',{directives:[{name:"mask",rawName:"v-mask",value:('#######-##.####.#.##.####'),expression:"'#######-##.####.#.##.####'"}],ref:"definput",staticClass:"erp-input erp-autocomplete float-icon input-hide-loading",class:{hasIcon: _vm.icon},attrs:{"required":_vm.required,"autofocus":_vm.autofocus,"color":"amber","placeholder":"","hide-underline":""},scopedSlots:_vm._u([(_vm.icon)?{key:"before",fn:function(){return [_c('u-icon',{staticClass:"m-l-sm",attrs:{"color":_vm.iconColor,"name":_vm.icon,"type":_vm.iconType}})]},proxy:true}:null],null,true),model:{value:(_vm.terms),callback:function ($$v) {_vm.terms=$$v},expression:"terms"}},[_c('u-popover',{ref:"popover",staticClass:"autocomplete-popover",attrs:{"anchor":"center right","offset":[5, 5],"anchor-click":false,"no-focus":true,"no-refocus":true},on:{"show":() => {
        this.$refs.definput.selectionOpen = true
        this.$emit('show')
      },"hide":() => {
        this.$refs.definput.selectionOpen = false
        /this.$emit('hide')
      }}},[_c('u-list',{attrs:{"separator":""}},[_c('u-item',{staticClass:"font-bold"},[_c('e-row',{staticClass:"full-width"},[_c('e-col',[_vm._v("Possível duplicata")]),_c('e-col',{staticClass:"flex items-center content-end text-right",staticStyle:{"max-width":"30px","margin-left":"50px"}},[_c('u-btn',{attrs:{"icon-type":"fa","icon":"times","flat":"","rounded":"","size":"xs","color":"grey-7"},on:{"click":function($event){return _vm.$refs.popover.hide()}}})],1)],1)],1),_vm._l((_vm.duplicates),function(p){return _c('u-item',{key:p.id},[_c('e-col',[_c('div',{staticClass:"font-bold1",domProps:{"innerHTML":_vm._s(_vm.extractMatch(p.numero))}})]),_c('e-col',{staticClass:"flex items-center content-end text-right",staticStyle:{"max-width":"30px","margin-left":"50px"}},[_c('u-btn',{staticClass:"rotate-45",attrs:{"icon-type":"fa","icon":"long-arrow-up","flat":"","round":"","size":"sm","color":"grey-7"},on:{"click":function($event){return _vm.gotoProcesso(p)}}})],1)],1)})],2)],1),(_vm.loading)?_c('sl-loading',{staticClass:"size-18",attrs:{"content":""}}):_vm._e(),(!_vm.loading && _vm.duplicates && _vm.duplicates.length)?_c('u-icon',{staticClass:"sl-loading",attrs:{"name":"exclamation-triangle","type":"fa"}}):_vm._e(),(_vm.isNew)?_c('div',{staticClass:"new"},[_c('span',{staticClass:"lbl"},[_vm._v("Novo")])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }