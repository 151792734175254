<template>
  <form @submit.prevent="save" class="w-m-content">
    <div class="w-m-header">
      <p class="font-14 font-bold no-margin no-select" style="color: #293e52">Registrar novo Processo</p>
    </div>
    <div class="w-m-body e-input-modern label-size-2 size1">
      <div class="font-11">
        <erp-s-field label="">
          <!--          <erp-input required autofocus v-model="pessoa.name" />-->
          <tribunal-select v-model="processo.tribunal" />
        </erp-s-field>
        <erp-s-field class="m-t-sm" label="Número">
          <!--          <erp-input required autofocus v-model="pessoa.name" />-->
          <numero-processo-input ref="numeroInput" :router="router" autofocus v-model="processo.numero"/>
        </erp-s-field>

      </div>
    </div>
    <div class="w-m-footer text-right footer-modern">
      <u-btn @click="$uloc.window.close($root.wid)" label="Cancelar" no-caps
             :color="'white'"
             text-color="grey-8"
             class="b-radius-3px btn-white m-r-sm"/>
      <u-btn :loading="loading" type="submit" label="Salvar processo" no-caps
             :color="'primary'"
             class="b-radius-3px"/>
    </div>
  </form>
</template>

<script>
// import ERow from '@/components/layout/components/Row'
import {ErpInput, ErpSField} from 'uloc-vue-plugin-erp'
// import ECol from '@/components/layout/components/Col'

import {newProcesso} from "@/domain/processos/services"
import NumeroProcessoInput from "@/components/processos/components/processos/include/NumeroProcessoInput"
import TribunalSelect from "@/components/processos/components/processos/include/TribunalSelect";

export default {
  name: 'ProcessoWindowModern',
  mixins: [],
  components: {
    TribunalSelect,
    NumeroProcessoInput,
    //ErpInput,
    ErpSField,
    //ERow,
    //ECol
  },
  props: ['options', 'router', 'type'],
  data() {
    return {
      loading: false,
      success: false,
      status: '',
      processo: {
        tribunal: null,
        numero: null,
        tipo: 1,
        ativarRobo: true
      }
    }
  },
  mounted() {
  },
  computed: {},
  methods: {
    save() {
      this.loading = true
      const data = JSON.parse(JSON.stringify(this.processo))
      const cb = (data) => {
        newProcesso(data)
            .then(response => {
              this.loading = false
              console.log(response)
              this.$uloc.window.emit(this.$root.wid, 'created', this.lista)
              this.success = true
              this.$uloc.window.close(this.$root.wid)
              this.$uloc.notify({
                color: 'black',
                message: `Novo processo "${response.data.numero}" criado.`,
                position: 'bottom-left',
                /*actions: [{
                  label: 'Visualizar',
                  handler: () => {
                    this.gotoProcesso(response.data)
                  }
                }]*/
              })
              this.gotoProcesso(response.data)
            })
            .catch(error => {
              this.loading = false
              this.alertApiError(error)
            })
      }
      try {
        /* if (data.tags && data.tags.length) {
          data.tags = data.tags.map(tag => tag.id)
        } */
        if (this.$refs.numeroInput.duplicates && this.$refs.numeroInput.duplicates.length) {
          this.$uloc.dialog({
            title: 'Possível pessoa duplicada',
            message: 'Existem nomes parecidos com este, você tem certeza que deseja continuar com o cadastro?',
            ok: 'Sim',
            cancel: 'Não',
            color: 'negative'
          }).then(() => {
            cb(data)
          })
          .catch(() => {
            this.loading = false
          })
        } else {
          cb(data)
        }
      } catch (e) {
        this.alertApiError(null, 'Erro de validação', null, e.message)
        this.loading = false
        return
      }

    },
    gotoProcesso (p) {
      let router = this.$router || this.router
      let routeData = router.resolve({name: 'processo', params: {id: p.id}, query: {new: '1'}})
      window.open(routeData.href, '_blank')
    }
  }
}
</script>
